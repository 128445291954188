import Project1 from "../../assets/Project1.jpeg";
import Project2 from "../../assets/Project2.jpeg";
import Project3 from "../../assets/Project3.jpeg";
import Project4 from "../../assets/Project4.jpeg";
import Project5 from "../../assets/Project6.jpg";
import Project6 from "../../assets/project5.jpg";
import Project7 from "../../assets/project7.png";


export const projectsData = [
    {
        id: 1,
        image: Project1,
        title: "Supreet Souharda Co-op Society",
        category: "Website",
        link:"https://maintenance.supreetsouharda.com",
    },
    {
        id: 2,
        image: Project7,
        title: "Shalom Trading",
        category: "Website",
        link:"https://shalomtrader.com",
    },
    {
        id: 7,
        image: Project2,
        title: "Aprameya IT Tech Fest NMIT",
        category: "Website",
        link:"https://aprameya.tech",
    },
    {
        id: 3,
        image: Project3,
        title: "NMIT Website Redesign",
        category: "Design",
        link:"https://www.figma.com/design/XvWJvpgFM73WNE5ROu9v6x/Untitled?node-id=0-1&t=OIu4sIUKBo2A3TQn-1",
    },
    {
        id: 4,
        image: Project4,
        title: "Whatsapp Local Sharing UI",
        category: "Design",
        link:"https://www.figma.com/design/XmVrnX8LzxBWb7ubHWBVbK/college-website-nmit-idea?t=OIu4sIUKBo2A3TQn-1",
    },
    {
        id: 5,
        image: Project5,
        title: "Sketchpad",
        category: "Website",
        link:"https://creativejason.site",
    },
    {
        id: 6,
        image: Project6,
        title: "JobShield",
        category: "Website",
        link:"https://jobshield-online.preview-domain.com/",
    },
    
];

export const projectsNav = [
    {
        name: "All",
    },
    {
        name: "Website",
    },
    {
        name: "Design",
    },
];